import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'lil-gui';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

THREE.ColorManagement.enabled = false;

/**
 * Base
 */
// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector('canvas.webgl');
// const color2 = new THREE.Color(0x5b86b0);
const color2 = new THREE.Color(0x000000);
// Scene
const scene = new THREE.Scene();
scene.background = color2;

// Axes Helper
// const axesHelper = new THREE.AxesHelper();
// scene.add(axesHelper);

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matCapTexture = textureLoader.load('/textures/matcaps/8.png');
const matCapTexture2 = textureLoader.load('/textures/matcaps/3.png');
// const matCapTexture3 = textureLoader.load('/textures/matcaps/4.png');

/** Fonts */
const fontLoader = new FontLoader();
fontLoader.load('/fonts/cherry_bomb_one_regular.json', (font) => {
    const textGeometry = new TextGeometry(`Alexis Louie `, {
        font,
        size: 0.5,
        height: 0.2, // the depth of the font
        curveSegments: 5, // unsure what this does but we can tweak it later
        bevelEnabled: true,
        bevelThickness: 0.03,
        bevelSize: 0.02, // uses different values from bevelThickness for demo purposes
        bevelOffset: 0,
        bevelSegments: 3,
    });

    // center the Text - option 1
    // textGeometry.computeBoundingBox();
    // textGeometry.translate(
    //     -(textGeometry.boundingBox.max.x - 0.02) * 0.5,
    //     -(textGeometry.boundingBox.max.y - 0.02) * 0.5,
    //     -(textGeometry.boundingBox.max.z - 0.03) * 0.5
    // );

    // center the Text - option 2
    textGeometry.center();

    // const textMaterial = new THREE.MeshBasicMaterial({ wireframe: true });
    const textMaterial = new THREE.MeshMatcapMaterial({
        matcap: matCapTexture,
    });
    const text = new THREE.Mesh(textGeometry, textMaterial);
    scene.add(text);

    const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
    const donutMaterial = new THREE.MeshMatcapMaterial({
        matcap: matCapTexture2,
    });

    for (let i = 0; i < 50; i++) {
        const donut = new THREE.Mesh(donutGeometry, donutMaterial);

        donut.position.x = (Math.random() - 0.5) * 12;
        donut.position.y = (Math.random() - 0.5) * 11;
        donut.position.z = (Math.random() - 0.5) * 11;

        // only need to do two axes because after it's rotated 2 ways, it covers 360 deg
        donut.rotation.x = Math.random() * Math.PI;
        donut.rotation.y = Math.random() * Math.PI;

        const scale = Math.random();
        // donut.scale.x = scale;
        // donut.scale.y = scale;
        // donut.scale.z = scale;
        donut.scale.set(scale, scale, scale);

        scene.add(donut);
    }

    const sphereGeometry = new THREE.SphereGeometry(0.2);
    const sphereMaterial = new THREE.MeshMatcapMaterial({
        matcap: matCapTexture2,
    });
    for (let i = 0; i < 50; i++) {
        const donut = new THREE.Mesh(sphereGeometry, sphereMaterial);

        donut.position.x = (Math.random() - 0.5) * 10;
        donut.position.y = (Math.random() - 0.5) * 10;
        donut.position.z = (Math.random() - 0.5) * 10;

        // only need to do two axes because after it's rotated 2 ways, it covers 360 deg
        donut.rotation.x = Math.random() * Math.PI;
        donut.rotation.y = Math.random() * Math.PI;

        const scale = Math.random();
        // donut.scale.x = scale;
        // donut.scale.y = scale;
        // donut.scale.z = scale;
        donut.scale.set(scale, scale, scale);

        scene.add(donut);
    }
});

/**
 * Object
 */

// Geometry
const particlesGeometry = new THREE.BufferGeometry(1, 32, 32);
const count = 500;

const positions = new Float32Array(count * 3);

for (let i = 0; i < count * 3; i++) {
    positions[i] = (Math.random() - 0.5) * 10;
}

const positionsAttribute = new THREE.BufferAttribute(positions, 3);
particlesGeometry.setAttribute('position', positionsAttribute);

//Material
const particlesMaterial = new THREE.PointsMaterial({
    size: 0.02,
    sizeAttenuation: true,
});

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial);
scene.add(particles);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100
);
camera.position.x = -1;
camera.position.y = 1;
camera.position.z = 3;
scene.add(camera);

/** This was just me playing with the camera angles */
// gui.add(camera.position, 'x', -5, 10).step(1);
// gui.add(camera.position, 'y', 0, 10).step(1);
// gui.add(camera.position, 'z', 0, 10).step(1);
// gui.add(camera.rotation, 'z', 0, 10)
//     .step(1)
//     .onChange(() => controls.update());

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.maxDistance = 8;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
});
renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();
